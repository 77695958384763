// src/components/Game/UserBalance.js
import React from "react";

const UserBalance = ({ user }) => {
  // Helper function to safely extract the number from $numberDecimal
  const extractDecimalValue = (field) => {
    return field?.$numberDecimal || "0.00";
  };

  const wallet = user?.wallet || {};
  let turnOver = wallet.turnOver.$numberDecimal;

  const balanceData = [
    { label: "เครดิต", value: extractDecimalValue(wallet.balance) },
    { label: "โบนัส", value: extractDecimalValue(wallet.bonus) },
    {
      label: "เทิร์นโอเวอร์",
      value: extractDecimalValue({
        $numberDecimal: (
          parseFloat(wallet.limit?.$numberDecimal || "0.00") -
          parseFloat(wallet.turnOver?.$numberDecimal || "0.00")
        ).toString(),
      }),
    },
  ];

  return (
    <div className="grid grid-cols-3 md:grid-cols-3 pb-2 pt-0 w-full gap-1 px-2 mt-1">
      {balanceData.map(({ label, value }, index) => (
        <button
          key={index}
          className="bg-pink-700 hover:bg-pink-500 text-white p-2 border-b-4 border-pink-700 hover:border-pink-500 rounded text-sm"
        >
          {label} : {parseFloat(value).toFixed(2)} บาท
        </button>
      ))}
    </div>
  );
};

export default UserBalance;

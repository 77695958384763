import React, { useState } from "react";
import axios from "axios";

function Slip() {
  const [image, setImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result); // Base64-encoded image
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    if (!image) {
      console.error("No image selected");
      return;
    }

    try {
      const response = await axios.post(
        "https://developer.easyslip.com/api/v1/verify",
        { image },
        {
          headers: {
            Authorization: "Bearer 40259399-e502-42fa-bfde-f0eb168ea0b8",
          },
        }
      );

      console.log("Response Data:", response.data);
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload and Verify</button>
    </div>
  );
}

export default Slip;
